<template>
    <div class="container">
        <div class="select">
            <select v-model="hours" @change="getReadings()">
                <option value="6">Last 6 hours</option>
                <option value="12">Last 12 hours</option>
                <option value="168">Last week</option>
                <option value="all">All records</option>
            </select>
        </div>
        <div class="table-responsive">
            <Line :data="dataObj.data" :options="dataObj.options" v-if="isReady" class='table'></Line>
        </div>    
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import axios from 'axios'

import { Chart } from 'chart.js/auto'
import { Line } from 'vue-chartjs'
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(zoomPlugin)

// const yScale = Chart.Scale.extend({})
// Chart.scaleService.registerScaleType('yScale', yScale, defaultConfigObject);

const readings = ref([])
const datetime1 = ref([])
const temperature1 = ref([])
const datetime3 = ref([])
const temperature3 = ref([])
const datetime4 = ref([])
const temperature4 = ref([])
const datetime5 = ref([])
const temperature5 = ref([])
const datetime6 = ref([])
const temperature6 = ref([])
const dataObj = ref({})
const hours = ref('6')
const callReadings = ref(null)
const isReady = ref(false)

const getReadings = async () => {
    isReady.value = false
    if (hours.value != 'all') {
        await axios
            .get('/api/v1/filter-readings/', {params: {time: hours.value}})
            .then(response => {
                readings.value = response.data
                // console.log(readings.value)    
            })
            .catch(error =>{
                console.log(error)
            })
    }
    else {
        await axios
            .get('/api/v1/readings/')
            .then(response => {
                readings.value = response.data
                console.log(readings.value)    
            })
            .catch(error =>{
                console.log(error)
            })
    }
    
    makeData()

    isReady.value = true
}


const makeData = () => {
    datetime1.value = readings.value.filter(elem => elem.sensor == 1).map(elem => elem.datetime)
    temperature1.value = readings.value.filter(elem => elem.sensor == 1).map(elem => elem.temperature)
    datetime3.value = readings.value.filter(elem => elem.sensor == 3).map(elem => elem.datetime)
    temperature3.value = readings.value.filter(elem => elem.sensor == 3).map(elem => elem.temperature)
    datetime4.value = readings.value.filter(elem => elem.sensor == 4).map(elem => elem.datetime)
    temperature4.value = readings.value.filter(elem => elem.sensor == 4).map(elem => elem.temperature)
    datetime5.value = readings.value.filter(elem => elem.sensor == 5).map(elem => elem.datetime)
    temperature5.value = readings.value.filter(elem => elem.sensor == 5).map(elem => elem.temperature)
    datetime6.value = readings.value.filter(elem => elem.sensor == 6).map(elem => elem.datetime)
    temperature6.value = readings.value.filter(elem => elem.sensor == 6).map(elem => elem.temperature)

    // isReady.value = true

    dataObj.value = {
        // type: 'line',
        data: {
            datasets: [
                {
                    label: 'Cucina',
                    data: temperature1.value.map((v, i) => ({ x: datetime1.value[i], y: v })),
                    yAxisID: 'y',
                    pointHoverRadius: 5,
                    pointHitRadius: 10
                },
                {
                    label: '4P Backup',
                    data: temperature3.value.map((v, i) => ({ x: datetime3.value[i], y: v })),
                    yAxisID: 'y',
                    pointHoverRadius: 5,
                    pointHitRadius: 10
                },
                {
                    label: '4P Destra',
                    data: temperature4.value.map((v, i) => ({ x: datetime4.value[i], y: v })),
                    yAxisID: 'y',
                    pointHoverRadius: 5,
                    pointHitRadius: 10
                },
                {
                    label: '4P Centrale',
                    data: temperature5.value.map((v, i) => ({ x: datetime5.value[i], y: v })),
                    yAxisID: 'y',
                    pointHoverRadius: 5,
                    pointHitRadius: 10
                },
                {
                    label: 'Azoto',
                    data: temperature6.value.map((v, i) => ({ x: datetime6.value[i], y: v })),
                    yAxisID: 'y',
                    pointHoverRadius: 5,
                    pointHitRadius: 10
                }
            ]
        },
        options: {
            responsive: true,
            // aspectRatio: 2.5/1,
            maintainAspectRatio: false,
            scales: {
                x: {
                    type: 'time',
                    time: { 
                        tooltipFormat: 'dd/MM HH:mm',
                        displayFormats: {
                            minute: 'dd/MM HH:mm',
                            hour: 'dd/MM HH:mm',
                            day: 'dd/MM HH:mm'
                        }
                    }
                },
y: {
    type: 'linear',
    display: true,
    position: 'left',
    // min: -100,
    // suggestedMin: -90,
    // max: -62
},
// y1: {
//     type: 'linear',
//     display: true,
//     position: 'right',
//     // min: -195.6, 
//     // suggestedMax: -194.7,
//     // max: -195,

//     // grid line settings
//     grid: {
//         drawOnChartArea: false, // only want the grid lines for one axis to show up
//     },
// }
            }, 
            plugins: {
                // tooltip: false,
                // zoom: {
                //     pan: {
                //         enabled: true,
                //         mode: 'x',
                //         modifierKey: 'ctrl',
                //     },
                //     zoom: {
                //         drag: {
                //             enabled: true
                //         },
                //         mode: 'x',
                //     },
                // }
                zoom: {
                    pan: {
                        enabled: true,
                        mode: 'x',
                        threshold: 5,
                    },
                    zoom: {
                    wheel: {
                        enabled: true
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x',
                    },
                }, 
                legend: { 
                    display: true,
                    labels: {
                        padding: 20
                    } 
                }
            }
        }
    }
}

callReadings.value = setInterval(getReadings, 60000*5)
// callReadings.value = setInterval(getReadings, 500)

onMounted(() => {
    getReadings()
})

onBeforeUnmount(() => {
    if (callReadings.value) {
        clearInterval(callReadings.value)
    }
}) 
</script>

<style lang="scss" scoped>
.container {
    // width: 95%;
    max-width: 95%;
    text-align: left;
    padding: 5px 10px 20px 10px;
    display:block;
    margin: 0 auto;
    // height: 90vh !important;
}

.table {
    height: 80vh !important;
}

select {
    appearance: none;
    background-color: #f9f9f9;
    border: solid 1px lightgray;
    border-radius: 4px;
    padding: 2px 10px;
    margin: 0;
    width: 150px;
    font-size: 10px;
    cursor: pointer;
    line-height: inherit;
}

select:focus{
    outline: none;
}

// canvas{
//     margin: 0 0;
// }
</style>
