<template>
    <div class="container pt-3">
        <p>
            You received an email because of a monitoring problem.
            <br>
            The system will continue to send an email every 5 minutes until the problem is resolved.
            <br>
            If you want to change the email sending rate, select one of the following options
        </p>
        <div class="d-grid gap-2 col-6 mt-4">
            <button class="btn btn-secondary btn-sm" type="button" @click="selectRemind(30)">
                Remind me in half an hour
            </button>
            <button class="btn btn-secondary btn-sm" type="button" @click="selectRemind(60)">
                Remind me in an hour
            </button>
            <button class="btn btn-secondary btn-sm" type="button" @click="selectRemind(null)">
                Don't remind me again
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const remind = ref(null)
const isUpdate = ref(false)
// const remind = ref(null)

const getRemind = async () => {
    
    const uuid = route.params.id
    
    await axios
        .get(`/api/v1/remind/${uuid}`)
        .then(response => {
            remind.value = response.data
            console.log(remind.value)
        })
        .catch(error =>{
            console.log(error)
        })
}

onMounted(() => {
    getRemind()
})

const selectRemind = async (remind_value) => {
    // remind.value = value
    // console.log(remind.value)
    const errors = []

    const uuid = route.params.id
    remind.value.remind = remind_value
    // console.log(person.value)

    await axios
        .patch(`/api/v1/remind/${uuid}/`, remind.value, {
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFTOKEN',
        })
        .then(response => {
            console.log(remind.value)
            // router.push('/')
            // createToast(
            //     'person field edited successfully', 
            //     paramStore.toastSuccess
            // )
            isUpdate.value = true
        })
        .catch(error => {
            if (error.response) {
                for (const property in error.response.data) {
                    errors.push(`${property}: ${error.response.data[property]}`)
                    // createToast(
                    //     `${property}: ${error.response.data[property]}`, 
                    //     paramStore.toastDanger
                    // )
                }
                console.log(errors.value)
                console.log(JSON.stringify(error.response.data))
            } else if (error.message) {
                console.log(JSON.stringify(error.message))
            } else {
                console.log(JSON.stringify(error))
            }
        })
    
    if (isUpdate.value) {
        await axios
            .get(`/api/v1/remind/${uuid}/send_remind/`)
            .then(response => {
                console.log(response.data)
                router.push('/')
            })
            .catch(error =>{
                console.log(error)
            })
    }
}
</script>
